<template>
	<Row class="!py-[32px]">
		<Container>
			<div
				class="testimonial-slider glide"
				:class="glideClass !== 'testimonial' ? glideClass + '-slider' : ''"
			>
				<div
					class="slider-navigation"
					data-glide-el="controls"
				>
					<button
						class="leftButton testimonial-btn"
						data-glide-dir="<"
					></button>
					<button
						class="rightButton testimonial-btn"
						data-glide-dir=">"
					></button>
				</div>
				<div
					class="glide__track"
					data-glide-el="track"
				>
					<div class="glide__slides">
						<div
							v-for="(testimonial, index) in testimonials"
							:key="index"
							class="glide__slide"
						>
							<div class="slide-interior">
								<NuxtLink
									target="_blank"
									:href="testimonial.url"
								>
									<p>{{ testimonial.text }}</p>
								</NuxtLink>

								<div
									class="d-flex flex-column align-items-center justify-content-center"
								>
									<div class="author-card">
										<div class="author-container">
											<span class="author-thumbnail">
												<img
													:src="testimonial.thumbnail"
													alt="Testimonial Commenter"
													loading="lazy"
												/>
											</span>
											<div class="author-info">
												<span class="author-name mb-[8px]">{{ testimonial.author }}</span>
												<span class="author-subtitle">StocksToTrade Subscriber</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="testimonial-indicators">
						<div
							v-for="(testimonial, index) in testimonials"
							:key="index"
							class="indicator"
							:class="{ active: index === activeIndex }"
							@click="goToTestimonial(index)"
						></div>
					</div>
				</div>
			</div>
		</Container>
	</Row>
</template>

<script setup>
import Glide from "@glidejs/glide";
import "@glidejs/glide/dist/css/glide.core.min.css";

const { glideClass } = defineProps({
	glideClass: {
		type: String,
		default: "testimonial"
	}
});
const testimonials = ref([
	{
		text:
			"First of all, I have had a great experience with StocksToTrade. The Alpha subscription should be no different because it’s the same family of people I am already familiar with. They are providing me with professional mentors,incredible education and wonderful support. Choosing a new subscription was a no brainer.",
		author: "Edward Lassow",
		thumbnail:
			"/images/edward-lassow.webp",
		url: "https://www.trustpilot.com/reviews/6532b7025c69a39e80f34f90"
	},
	{
		text:
			"It takes some time to get acquainted with the system. There is a lot to learn first and then follow along. If you understand the entry points and trade momentum, then success is there to be taken. I am now a careful trader that has a new chance to be a winner in the stock market. It's an advantage to have the platform with Oracle and use the screeners. I finally have free time now, thank you StocksToTrade!",
		author: "Matthew Chilenskas",
		url: "https://www.trustpilot.com/reviews/64bde3bc3af9776206007c4b",
		thumbnail:
			"https://user-images.trustpilot.com/64bde3930d13ea0012413670/73x73.png"
	},
	{
		url: "https://www.trustpilot.com/reviews/652695545e3aea4f3e1743d6",
		text:
			"The STT team has made this what it is. Programs/Scanners are awesome, but the people factor is what makes this GOLD! There are so many programs out there that promise the world. In the end, you always spend a lot of money and end up with little to no value out of it. STT has been different. Massive amounts of knowledge and education packed in the University platform, Tons of Webinars and Lives to participate in and best of all, the trainers are people that have gone through this and perfected their trade.",
		author: "Landon",
		thumbnail: "/images/subscriber_landon.webp"
	},
	{
		url: "https://www.trustpilot.com/reviews/643847557a3422032fb4e4eb",
		text: `The personnel involved with STT and the Daily Income System rocks !! They are very patient in showing you how to learn without you getting upset, plus they make
sure to repeat the lesson over to you so it can be instilled into your thought process.`,
		author: "Everett",
		thumbnail: "/images/subscriber_alex_b.webp"
	}
]);

const glide = ref(null);
let activeIndex = ref(0);

const goToTestimonial = (index = 0) => {
	if (glide.value) {
		glide.value.go("=" + index);
		activeIndex.value = index;
		glide.value.update();
	}
};

const glideInit = async () => {
	glide.value = new Glide(`.${glideClass}-slider`, {
		type: "carousel",
		perView: 1,
		startAt: 0,
		focusAt: "center",
		pagination: {
			el: `.${glideClass}-indicators`,
			clickable: true
		}
	});

	if (glide.value) {
		glide.value.on("run", () => {
			const index = glide.value.index;
			activeIndex.value = index;
		});

		glide.value.mount();

		setTimeout(() => {
			goToTestimonial();
		}, 100);
	}
};

onMounted(async () => {
	if (process.client) {
		await glideInit();
	}
});
</script>

<style lang="scss" scoped>
.glide__track {
	position: relative;
}
.testimonial-slider {
	text-align: center;
	padding: 16px;
	margin: auto;
	max-width: 890px;
	@include breakpoint(md) {
		padding: 32px;
	}

	.slide-interior {
		max-width: 800px;
		margin: auto;
	}

	.testimonial-btn {
		height: 40px;
	}
}

.testimonial-text-container {
	text-align: center;

	@include breakpoint(md) {
		height: 81px;
		padding: 0 64px;
	}
}

.testimonial-text {
	font-size: 16px;

	@include breakpoint(md) {
		font-size: 18px;
	}
}

.testimonial-item {
	display: none;
	text-align: center;
}

.testimonial-item.active {
	display: block;
}

.slider-navigation {
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
	position: absolute;
	margin: auto;
	left: 0;
	right: 0;
	transform: unset !important;
	top: -36px;
	bottom: 0;

	align-items: flex-end;

	@include breakpoint(lg) {
		align-items: center;
	}
}

.leftButton::before {
	content: "";
	display: inline-block;
	width: 32px;
	height: 32px;
	margin-right: 16px;
	background-image: url("/images/arrow-left.svg");
	background-size: cover;
	vertical-align: middle;
}

.rightButton::before {
	content: "";
	display: inline-block;
	width: 32px;
	height: 32px;
	margin-left: 16px;
	background-image: url("/images/arrow-right.svg");
	background-size: cover;
	vertical-align: middle;
}

.author-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 32px;

	@include breakpoint(md) {
		margin-top: 16px;
	}
}

.author-info {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	@include breakpoint(md) {
		align-items: flex-start;
		text-align: left;
	}
}

.author-container {
	display: flex;
	align-items: center;
	gap: 16px;
	flex-direction: column;
	@include breakpoint(md) {
		flex-direction: row;
		gap: 16px;
	}
}

.author-subtitle {
	color: #7d8494;
	font-size: 14px;
	font-weight: 400;
}

.author-thumbnail img {
	height: 48px;
	width: 48px;
	border-radius: 50%;
}

.testimonial-indicators {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 32px;

	.indicator {
		width: 16px;
		height: 16px;
		margin: 0 6px;
		border-radius: 50%;
		background-color: #ccc;
		cursor: pointer;
	}
}
.indicator.active {
	background: linear-gradient(90deg, #7da3ff, #b370ff) !important;
}
</style>
